import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { loginSchema } from "constants/formSchema";
import { handleLogin } from "redux/actions/auth";
import { ROUTES } from "router/routes";
import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { createPortal } from "react-dom";
import Terms from "components/terms";

///INITIALVALUES

const defaultValues = {
  email: "",
  fullname: "",
  password: "",
};

const SignUpExtPage = ({ isTalent }) => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [buttonHover, setButtonHover] = useState(false);

  const [email, set_email] = useState("");
  const [firstname, set_firstname] = useState("");
  const [lastname, set_lastname] = useState("");
  const [mobile, set_mobile] = useState("");
  const [org_name, set_org_name] = useState("");
  const [error, set_error] = useState("");
  const [error_message, set_error_message] = useState("");
  const [talent_profile, set_talent_profile] = useState({});

  const [show_terms, set_show_terms] = useState(false);

  const base_url =
    process.env.NODE_ENV === "development"
      ? "http://Milkeshs-MacBook-Pro.local:8002"
      : "https://talentlabapi.peopleasset.in";

  console.log(base_url);

  const validate_details = (isTalent = true) => {
    // email has to be valid and filled in
    // mobile number has to be valid filled in
    // name has to be valid and filled in

    const textRegex = /([a-zA-Z].*?){3}/;
    const mobileRegex = /^(?:\+[0-9]{1,3})?[0-9]{10,15}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const areTextFieldsValid = isTalent
      ? textRegex.test(firstname) && textRegex.test(lastname)
      : textRegex.test(firstname) &&
        textRegex.test(lastname) &&
        textRegex.test(org_name);

    const isEmailValid = emailRegex.test(email);

    const isMobileValid = mobileRegex.test(mobile);

    return areTextFieldsValid && isEmailValid && isMobileValid;
  };

  const sign_up_user = async () => {
    try {
      if (!validate_details()) {
        throw new Error("Please fill valid email and mobile details");
      }

      const profile_response = await axios.post(
        `${base_url}/user/create-talent-profile`,
        {
          firstName: firstname,
          lastName: lastname,
          middleName: "",
          country: "",
          city: "",
          state: "",
          phoneNumber: [mobile],
          gender: 1,
          profession: "",
          executiveSummary: "",
          willingToRelocate: false,
          email: email,
          selfSignUp: true,
        }
      );

      // console.log(profile_response.data.data);

      set_talent_profile(profile_response?.data?.data);

      if (profile_response.data.statusCode === 200) {
        // using formdata as this part accepts form data due to multipart
        const formdata = new FormData();
        formdata.append("name", firstname);
        formdata.append("middleName", "");
        formdata.append("lastName", lastname);
        formdata.append("email", email);
        formdata.append("phoneNumber", mobile);
        formdata.append("talentLabRole", "Talent");
        formdata.append("roles", JSON.stringify([]));
        formdata.append("selfSignUp", true);
        formdata.append("commonId", profile_response.data.data._id);

        const user_response = await axios.post(
          `${base_url}/user/create-talent-user`,
          formdata
        );

        if (user_response.data.statusCode === 200) {
          toast.success(
            <ToastMessage
              title={"Signed up successfully"}
              message={"Set your password to continue"}
            />,
            {
              autoClose: 20000,
            }
          );
          navigate(`/set-password/${user_response.data.data._id}/1`);
        }
      }
    } catch (error) {
      console.log(error.message);
      toast.error(
        <ToastMessage
          title={"Error Occured!"}
          message={"There was error creating your profile. Please try again."}
        />,
        {
          autoClose: 20000,
        }
      );
    }
  };

  const sign_up_client = async () => {
    try {
      if (!validate_details(false)) {
        throw new Error("Please fill valid email and mobile details");
      }

      const primary_contact = [
        {
          name: `${firstname} ${lastname}`,
          designation: "",
          email: email,
          phoneNumber: mobile,
          isPrimary: true,
        },
        // {
        //   name: `${firstname} ${lastname}`,
        //   designation: "",
        //   email: email,
        //   phoneNumber: "",
        //   isPrimary: true,
        // },
      ];

      const contactPersons = [];

      const profile_form_data = new FormData();
      profile_form_data.append("name", org_name);
      profile_form_data.append(
        "contactPersons",
        JSON.stringify(primary_contact)
      );
      //profile_form_data.append("contactPersons", "[]");
      profile_form_data.append("selfSignUp", true);
      const profile_response = await axios.post(
        `${base_url}/user/create-client-profile`,
        profile_form_data
      );

      if (profile_response.data.statusCode === 200) {
        // using formdata as this part accepts form data due to multipart
        const formdata = new FormData();
        formdata.append("name", firstname);
        formdata.append("middleName", "");
        formdata.append("lastName", lastname);
        formdata.append("email", email);
        formdata.append("phoneNumber", mobile);
        formdata.append("talentLabRole", "Organizations");
        formdata.append("organization_role", "Organization Admin");
        formdata.append("roles", JSON.stringify([]));
        formdata.append("selfSignUp", true);
        formdata.append("commonId", profile_response.data.data._id);

        const user_response = await axios.post(
          `${base_url}/user/create-talent-user`,
          formdata
        );

        if (user_response.data.statusCode === 200) {
          toast.success(
            <ToastMessage
              title={"Signed up successfully"}
              message={"Set your password to continue"}
            />,
            {
              autoClose: 20000,
            }
          );
          navigate(`/set-password/${user_response.data.data._id}/1`);
        }
      }
    } catch (error) {
      // console.log(error);
      const err = error?.response?.data;
      console.log(err, "err");
      toast.error(
        <ToastMessage
          title={
            err?.responseType === "NAME_ALREADY_EXIST"
              ? "Organization already exists"
              : err?.responseType === "ALREADY_EXIST"
              ? "Email already exists"
              : "Error Occured!"
          }
          message={
            err?.responseType === "NAME_ALREADY_EXIST"
              ? `The organization you are signing up with has already signed up. Please contact us at <a href="mailto:connect@talentlab.tech">connect@talentlab.tech</a> for more info.`
              : err?.responseType === "ALREADY_EXIST"
              ? `The email you are signing up with has already registered with us. Please contact us at <a href="mailto:connect@talentlab.tech">connect@talentlab.tech</a> for more info.`
              : "There was error creating your profile. Please try again."
          }
        />,
        {
          autoClose: 20000,
        }
      );
    }
  };

  /// SUBMIT HANDLER
  return (
    <div className={styles.mainContainer}>
      <div className={styles.middleBox}>
        <div>
          <img src={IMAGES.LOGO_SVG} />
        </div>
        <div>
          <div className={styles.headingContainer}>
            <h1 className={styles.headingtext}>
              {isTalent ? "Candidate Sign Up" : "Client Portal"}
            </h1>
            <p className={styles.headingPara}>
              {isTalent
                ? " Your search for a great career ends here "
                : "Find the right talent to strengthen your organization"}
            </p>
          </div>

          <form>
            <div className={styles.formContainer}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  color: "red",
                  margin: 0,
                }}
              >
                <p
                  style={{
                    color: "red",
                    margin: 0,
                    fontSize: "10px",
                  }}
                >
                  <i>
                    <sup>*</sup> All fields are mandatory
                  </i>
                </p>
              </div>

              {/* name */}

              {!isTalent && (
                <div
                  className={styles.inputContainer}
                  style={{ marginBottom: "1rem" }}
                >
                  <div className={styles.labelContainer}>
                    {/* <span className={styles.errorMsg}>
                
                      </span> */}
                  </div>

                  <input
                    name="organisationName"
                    className={styles.inputStyles}
                    type="text"
                    placeholder="Organisation Name"
                    onInput={(e) => {
                      set_org_name(e.target.value);
                    }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  gap: "1rem",
                }}
              >
                <div
                  className={styles.inputContainer}
                  style={{ marginBottom: "1rem" }}
                >
                  <div className={styles.labelContainer}>
                    {/* <span className={styles.errorMsg}>
                
                      </span> */}
                  </div>

                  <input
                    name="firstname"
                    className={styles.inputStyles}
                    type="text"
                    placeholder="First Name"
                    onInput={(e) => {
                      set_firstname(e.target.value);
                    }}
                  />
                </div>
                <div
                  className={styles.inputContainer}
                  style={{ marginBottom: "1rem" }}
                >
                  <div className={styles.labelContainer}>
                    {/* <span className={styles.errorMsg}>
                
                      </span> */}
                  </div>

                  <input
                    name="lastname"
                    className={styles.inputStyles}
                    type="text"
                    placeholder="Last Name"
                    onInput={(e) => {
                      set_lastname(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div
                className={styles.inputContainer}
                style={{ marginBottom: "1rem" }}
              >
                <div className={styles.labelContainer}></div>

                <input
                  name="email"
                  className={styles.inputStyles}
                  type="email"
                  placeholder="Email Address"
                  onInput={(e) => {
                    set_email(e.target.value);
                  }}
                />
              </div>

              <div
                className={styles.inputContainer}
                style={{ marginBottom: "1rem" }}
              >
                <div className={styles.labelContainer}></div>

                <input
                  name="phone"
                  className={styles.inputStyles}
                  type="text"
                  pattern=""
                  placeholder="Mobile Number"
                  onInput={(e) => {
                    set_mobile(e.target.value);
                  }}
                />
              </div>

              <div className={styles.labelContainer}></div>
              {isTalent && (
                <button
                  type="submit"
                  className={styles.button}
                  onClick={(e) => {
                    e.preventDefault();
                    // sign_up_user();
                    set_show_terms(true);
                  }}
                >
                  <span>{loading ? "Loading..." : "Sign Up"}</span>
                  <img
                    src={buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW}
                    className={styles.arrowStyles}
                  />
                </button>
              )}

              {!isTalent && (
                <button
                  type="submit"
                  className={styles.button}
                  onClick={(e) => {
                    e.preventDefault();
                    // sign_up_client();
                    set_show_terms(true);
                  }}
                >
                  <span>{loading ? "Loading..." : "Sign Up"}</span>
                  <img
                    src={buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW}
                    className={styles.arrowStyles}
                  />
                </button>
              )}
              <div className={styles.forgetContainer}>
                <span
                  className={styles.forgetText}
                  onClick={() =>
                    navigate(
                      isTalent ? ROUTES.TALENT_LOGIN : ROUTES.CLIENT_LOGIN
                    )
                  }
                >
                  Already a user? Sign In
                </span>
              </div>
            </div>
          </form>
        </div>
        {show_terms &&
          createPortal(
            <Terms
              close={() => {
                set_show_terms(false);
              }}
              accept={() => {
                isTalent ? sign_up_user() : sign_up_client();
              }}
            ></Terms>,
            document.body
          )}

        <LoginFooter />
      </div>
    </div>
  );
};

export default SignUpExtPage;
