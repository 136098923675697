import React from "react";
import { useState } from "react";
import { createPortal } from "react-dom";
import "./index.css";
import { addEditApi } from "api/commonApis";
import { APIS } from "api";
import { useEffect } from "react";
import { getState } from "redux/store";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { ORG_ROLES_OPTIONS } from "constants/contants";
import FormInput from "components/forms/formInput";
import { Formik } from "formik";

const SendInvite = ({ organisation }) => {
  const [invitation_handler, set_invitation_handler] = useState(false);
  const [loading, set_loading] = useState(false);

  const [first_name, set_first_name] = useState();
  const [last_name, set_last_name] = useState();
  const [email, set_email] = useState();
  const [role, set_role] = useState(ORG_ROLES_OPTIONS[0]?.value);
  const [current_user, set_current_user] = useState();

  useEffect(() => {
    set_current_user(getState()?.auth?.data);
  }, []);

  const base_url =
    process.env.NODE_ENV === "development"
      ? "http://Milkeshs-MacBook-Pro.local:8002"
      : "https://talentlabapi.peopleasset.in";

  const validate_form = () => {
    // email has to be valid and filled in
    // mobile number has to be valid filled in
    // name has to be valid and filled in
    const textRegex = /([a-zA-Z].*?){3}/;

    const emailRegex =
      /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/;

    const areTextFieldsValid =
      textRegex.test(first_name) && textRegex.test(last_name);

    const isEmailValid = emailRegex.test(email);

    return areTextFieldsValid && isEmailValid;
  };

  // function to create the user
  const send_an_invite = async () => {
    try {
      set_loading(true);

      addEditApi(APIS.SEND_INVITE, {
        first_name: first_name,
        last_name: last_name,
        email: email,
        sent_for: organisation._id,
        sent_on: new Date(),
        sent_by: current_user._id,
        invitation_accepted: false,
        role: role,
        invitation_expired: false,
      }).then(async (response) => {
        console.log(response);
        toast.success(
          <ToastMessage
            title={"Invite sent successfully"}
            message={"Page will refresh automatically"}
          />
        );
        set_loading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      console.log(error);
      toast.error(
        <ToastMessage
          title={"Error"}
          message={"There was an error sending an invite"}
        />,
        {
          autoClose: 2000,
        }
      );
      set_loading(false);
    }
  };

  return (
    <div className="tw-flex-none">
      <button
        onClick={() => {
          set_invitation_handler(true);
        }}
        className="lg:tw-bg-[#011fc5] tw-text-xs lg:hover:tw-bg-transparent lg:hover:tw-border-current tw-shadow-sm flux tw-border lg:tw-text-white lg:hover:tw-text-[#011fc5] tw-px-3 tw-py-2 tw-rounded-md "
      >
        Add team member
      </button>

      {invitation_handler &&
        createPortal(
          <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-screen tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center">
            <div className="tw-rounded-md tw-p-8 tw-w-5/12 tw-bg-white">
              <p className="tw-text-black tw-font-bold tw-text-xl  !tw-m-0">
                Send invite to join TalentLab
              </p>
              <div className="tw-my-4">
                <form>
                  <div className="mb-2 has-[:focus]:!tw-text-black tw-text-[#a3a3a3]">
                    <label className=" tw-text-[10px] my-1" htmlFor="">
                      First Name
                    </label>
                    <input
                      onInput={(e) => {
                        set_first_name(e.target.value);
                      }}
                      className="tw-w-full tw-rounded-md tw-border tw-border-[#ddd] tw-p-4 tw-max-h-9 focus:tw-border-[#011cf5] tw-outline-none tw-text-xs tw-text-[#2f2f2f] tw-capitalize"
                      type="text"
                    />
                  </div>
                  <div className="mb-2 has-[:focus]:!tw-text-black tw-text-[#a3a3a3]">
                    <label className=" tw-text-[10px] my-1" htmlFor="">
                      Last Name
                    </label>
                    <input
                      onInput={(e) => {
                        set_last_name(e.target.value);
                      }}
                      type="text"
                      className="tw-w-full tw-rounded-md tw-border tw-border-[#ddd] tw-p-4 tw-max-h-9 focus:tw-border-[#011cf5] tw-outline-none tw-text-xs tw-text-[#2f2f2f] tw-capitalize"
                    />
                  </div>
                  <div className="mb-2 has-[:focus]:!tw-text-black tw-text-[#a3a3a3] tw-relative">
                    <label className=" tw-text-[10px] my-1" htmlFor="">
                      Email Address
                    </label>
                    <input
                      onInput={(e) => {
                        set_email(e.target.value);
                      }}
                      className="tw-w-full tw-rounded-md tw-border tw-border-[#ddd] tw-p-4 tw-max-h-9 focus:tw-border-[#011cf5] tw-outline-none tw-text-xs tw-text-[#2f2f2f]"
                      type="email"
                    />
                    <span>
                      <span className="tw-text-[10px] tw-text-slate-400 tw-italic tw-block tw-my-1">
                        Non organizational and personal email service providers
                        such as hotmail, gmail, googlemail, yahoo, gmx, ymail,
                        outlook, bluewin, protonmail, t-online, web, aol and
                        live are not allowed.
                      </span>
                    </span>
                  </div>
                  <div>
                    <FormInput
                      name="role"
                      label="Organization Role"
                      type="select"
                      options={ORG_ROLES_OPTIONS}
                      defaultValue={ORG_ROLES_OPTIONS[0]}
                      onChange={(data) => {
                        console.log(data);
                        //setFieldValue("organization_role", data?.value);
                        set_role(data?.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="tw-mt-2 tw-flex tw-justify-end tw-space-x-4">
                <button
                  className="tw-px-4 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-py-2 tw-rounded-md tw-bg-slate-100"
                  onClick={() => {
                    set_invitation_handler(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={!validate_form()}
                  className="tw-px-4 tw-min-w-[170px] disabled:hover:tw-bg-[#011fc5] disabled:hover:tw-text-white disabled:tw-opacity-40 disabled:tw-cursor-not-allowed tw-text-xs hover:tw-border-current hover:tw-bg-transparent hover:tw-text-[#011fc5] tw-border tw-border-transparent tw-text-white tw-py-2 tw-rounded-md tw-bg-[#011fc5]"
                  onClick={(e) => {
                    e.preventDefault();
                    send_an_invite();
                  }}
                >
                  {loading ? (
                    <svg
                      class="tw-animate-spin tw-h-5 tw-w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Send invite"
                  )}
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default SendInvite;
