import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IMAGES } from "constants/assets";
import LoginFooter from "components/loginFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { loginSchema } from "constants/formSchema";
import { handleLogin } from "redux/actions/auth";
import { ROUTES } from "router/routes";
import axios from "axios";
import { toast } from "react-toastify";
import ToastMessage from "components/layout/toast";
import { createPortal } from "react-dom";
import Terms from "components/terms";
import { useEffect } from "react";
import { addEditApi, listApi } from "api/commonApis";
import { APIS } from "api";
import "./index.css";

///INITIALVALUES

// dont create new org only sign them up
// set invitation acceptance to true
// if invite is accepted page should show that the invite has been accpeted

const defaultValues = {
  email: "",
  fullname: "",
  password: "",
};

const InvitationSignUp = ({ isTalent }) => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [buttonHover, setButtonHover] = useState(false);

  const [email, set_email] = useState("");
  const [firstname, set_firstname] = useState("");
  const [lastname, set_lastname] = useState("");
  const [mobile, set_mobile] = useState("");
  const [org_name, set_org_name] = useState("");
  const [org_role, set_org_role] = useState("");
  const [error, set_error] = useState("");
  const [error_message, set_error_message] = useState("");
  const [talent_profile, set_talent_profile] = useState({});

  const [show_terms, set_show_terms] = useState(false);

  const base_url =
    process.env.NODE_ENV === "development"
      ? "http://Milkeshs-MacBook-Pro.local:8002"
      : "https://talentlabapi.peopleasset.in";

  console.log(base_url);

  const validate_details = (isTalent = true) => {
    // email has to be valid and filled in
    // mobile number has to be valid filled in
    // name has to be valid and filled in

    const textRegex = /([a-zA-Z].*?){3}/;
    const mobileRegex = /^(?:\+[0-9]{1,3})?[0-9]{10,15}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const areTextFieldsValid = isTalent
      ? textRegex.test(firstname) && textRegex.test(lastname)
      : textRegex.test(firstname) &&
        textRegex.test(lastname) &&
        textRegex.test(org_name);

    const isEmailValid = emailRegex.test(email);

    const isMobileValid = mobileRegex.test(mobile);

    return areTextFieldsValid && isEmailValid && isMobileValid;
  };

  // const sign_up_user = async () => {
  //   try {
  //     if (!validate_details()) {
  //       throw new Error("Please fill valid email and mobile details");
  //     }

  //     const profile_response = await axios.post(
  //       `${base_url}/user/create-talent-profile`,
  //       {
  //         firstName: firstname,
  //         lastName: lastname,
  //         middleName: "",
  //         country: "",
  //         city: "",
  //         state: "",
  //         phoneNumber: [mobile],
  //         gender: 1,
  //         profession: "",
  //         executiveSummary: "",
  //         willingToRelocate: false,
  //         email: email,
  //         selfSignUp: true,
  //       }
  //     );

  //     // console.log(profile_response.data.data);

  //     set_talent_profile(profile_response?.data?.data);

  //     if (profile_response.data.statusCode === 200) {
  //       // using formdata as this part accepts form data due to multipart
  //       const formdata = new FormData();
  //       formdata.append("name", firstname);
  //       formdata.append("middleName", "");
  //       formdata.append("lastName", lastname);
  //       formdata.append("email", email);
  //       formdata.append("phoneNumber", mobile);
  //       formdata.append("talentLabRole", "Talent");
  //       formdata.append("roles", JSON.stringify([]));
  //       formdata.append("selfSignUp", true);
  //       formdata.append("commonId", profile_response.data.data._id);

  //       const user_response = await axios.post(
  //         `${base_url}/user/create-talent-user`,
  //         formdata
  //       );

  //       if (user_response.data.statusCode === 200) {
  //         toast.success(
  //           <ToastMessage
  //             title={"Signed up successfully"}
  //             message={"Set your password to continue"}
  //           />,
  //           {
  //             autoClose: 20000,
  //           }
  //         );
  //         navigate(`/set-password/${user_response.data.data._id}/1`);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     toast.error(
  //       <ToastMessage
  //         title={"Error Occured!"}
  //         message={"There was error creating your profile. Please try again."}
  //       />,
  //       {
  //         autoClose: 20000,
  //       }
  //     );
  //   }
  // };

  const sign_up_client = async () => {
    try {
      if (!validate_details(false)) {
        throw new Error(
          "Please fill valid first name, last name and mobile details"
        );
      }

      // using formdata as this part accepts form data due to multipart
      const formdata = new FormData();
      formdata.append("name", firstname);
      formdata.append("middleName", "");
      formdata.append("lastName", lastname);
      formdata.append("email", email);
      formdata.append("phoneNumber", mobile);
      formdata.append("talentLabRole", "Organizations");
      formdata.append("organization_role", org_role);
      formdata.append("roles", JSON.stringify([]));
      formdata.append("selfSignUp", true);
      formdata.append("commonId", invitation?.sent_for?._id);
      formdata.append("agreement_accepted", true);
      formdata.append("agreement_accepted_on", new Date());

      const user_response = await axios.post(
        `${base_url}/user/create-talent-user`,
        formdata
      );

      if (user_response.data.statusCode === 200) {
        //when the user is created, pass the user id to the invitation for the record
        const invite_accepted = await axios.post(
          `${base_url}${APIS.ACCEPT_INVITE}`,
          { id: id, conversion_id: user_response?.data?.data?._id }
        );

        console.log(invite_accepted, "invite_accepted");

        toast.success(
          <ToastMessage
            title={"Signed up successfully"}
            message={"Set your password to continue"}
          />,
          {
            autoClose: 10000,
          }
        );
        navigate(`/set-password/${user_response.data.data._id}/1`);
      }
    } catch (error) {
      // console.log(error);
      const err = error?.response?.data;
      console.log({ err, error }, "err");
      toast.error(
        <ToastMessage
          title={
            err?.responseType === "NAME_ALREADY_EXIST"
              ? "Organization already exists"
              : err?.responseType === "ALREADY_EXIST"
              ? "Email already exists"
              : "Error Occured!"
          }
          message={
            err?.responseType === "NAME_ALREADY_EXIST"
              ? `The organization you are signing up with has already signed up. Please contact us at <a href="mailto:connect@talentlab.tech">connect@talentlab.tech</a> for more info.`
              : err?.responseType === "ALREADY_EXIST"
              ? `The email you are signing up with has already registered with us. Please contact us at <a href="mailto:connect@talentlab.tech">connect@talentlab.tech</a> for more info.`
              : "There was error creating your profile. Please try again."
          }
        />,
        {
          autoClose: 10000,
        }
      );
    }
  };

  const { id } = useParams();

  const [invitation, set_invitation] = useState({});

  const [other_invitations, set_other_invitations] = useState([]);

  const [_loading, set_loading] = useState(false);

  useEffect(() => {
    if (!isTalent) {
      set_loading(true);

      listApi(APIS.FETCH_INVITE_DETAILS, {
        id: id,
        for_sign_up: true,
      }).then((result) => {
        // set invitations
        console.log(result.data[0]);
        set_invitation(result.data[0]);
        set_firstname(result.data[0].first_name);
        set_lastname(result.data[0].last_name);
        set_email(result.data[0].email);
        set_org_name(result.data[0]?.sent_for?.name);
        set_org_role(result.data[0]?.role);

        listApi(APIS.FETCH_INVITE_DETAILS, {
          id: result.data[0]?.sent_for?._id,
        }).then((res) => {
          // set invitations
          console.log(res.data, {});
          set_other_invitations(res.data);
        });

        set_loading(false);
      });
    }
  }, []);

  /// SUBMIT HANDLER
  return (
    <div className={styles.mainContainer}>
      <div className={styles.middleBox}>
        <div>
          <img src={IMAGES.LOGO_SVG} />
        </div>
        <div>
          <div className={styles.headingContainer}>
            <h1 className={styles.headingtext}>
              {isTalent ? "Candidate Sign Up" : "Client Portal"}
            </h1>
            <p className={styles.headingPara}>
              {isTalent
                ? " Your search for a great career ends here "
                : "Find the right talent to strengthen your organization"}
            </p>
          </div>

          {!invitation?.invitation_accepted &&
            !invitation?.invitation_expired &&
            other_invitations.filter((i) => i.invitation_accepted).length <
              5 && (
              <form>
                <div className={styles.formContainer}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: "red",
                      margin: 0,
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        margin: 0,
                        fontSize: "10px",
                      }}
                    >
                      <i>
                        <sup>*</sup> All fields are mandatory
                      </i>
                    </p>
                  </div>

                  {/* name */}

                  {!isTalent && (
                    <div
                      className={styles.inputContainer}
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className={styles.labelContainer}>
                        {/* <span className={styles.errorMsg}>
                
                      </span> */}
                      </div>

                      <input
                        name="organisationName"
                        className={
                          styles.inputStyles +
                          " disabled:tw-text-slate-400 disabled:tw-bg-slate-300 disabled:tw-cursor-not-allowed"
                        }
                        type="text"
                        placeholder="Organisation Name"
                        // onInput={(e) => {
                        //   set_org_name(e.target.value);
                        // }}
                        disabled
                        value={org_name}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                      gap: "1rem",
                    }}
                  >
                    <div
                      className={styles.inputContainer}
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className={styles.labelContainer}>
                        {/* <span className={styles.errorMsg}>
                
                      </span> */}
                      </div>

                      <input
                        name="firstname"
                        className={
                          styles.inputStyles +
                          " disabled:tw-text-slate-400 disabled:tw-bg-slate-300 disabled:tw-cursor-not-allowed"
                        }
                        type="text"
                        value={firstname}
                        placeholder="First Name"
                        onInput={(e) => {
                          set_firstname(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className={styles.inputContainer}
                      style={{ marginBottom: "1rem" }}
                    >
                      <div className={styles.labelContainer}>
                        {/* <span className={styles.errorMsg}>
                
                      </span> */}
                      </div>

                      <input
                        name="lastname"
                        className={
                          styles.inputStyles +
                          " disabled:tw-text-slate-400 disabled:tw-bg-slate-300 disabled:tw-cursor-not-allowed"
                        }
                        type="text"
                        value={lastname}
                        placeholder="Last Name"
                        onInput={(e) => {
                          set_lastname(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className={styles.inputContainer}
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className={styles.labelContainer}></div>

                    <input
                      name="email"
                      className={
                        styles.inputStyles +
                        " disabled:tw-text-slate-400 disabled:tw-bg-slate-300 disabled:tw-cursor-not-allowed"
                      }
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      disabled
                      // onInput={(e) => {
                      //   set_email(e.target.value);
                      // }}
                    />
                  </div>

                  <div
                    className={styles.inputContainer}
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className={styles.labelContainer}></div>

                    <input
                      name="phone"
                      className={
                        styles.inputStyles +
                        " disabled:tw-text-slate-600 disabled:tw-bg-slate-100"
                      }
                      type="text"
                      pattern=""
                      placeholder="Mobile Number"
                      onInput={(e) => {
                        set_mobile(e.target.value);
                      }}
                    />
                  </div>

                  <div className={styles.labelContainer}></div>
                  {isTalent && (
                    <button
                      type="submit"
                      className={styles.button}
                      onClick={(e) => {
                        e.preventDefault();
                        // sign_up_user();
                        set_show_terms(true);
                      }}
                    >
                      <span>{loading ? "Loading..." : "Sign Up"}</span>
                      <img
                        src={
                          buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW
                        }
                        className={styles.arrowStyles}
                      />
                    </button>
                  )}

                  {!isTalent && (
                    <button
                      type="submit"
                      className={styles.button}
                      onClick={(e) => {
                        e.preventDefault();
                        // sign_up_client();
                        set_show_terms(true);
                      }}
                    >
                      <span>{loading ? "Loading..." : "Sign Up"}</span>
                      <img
                        src={
                          buttonHover ? IMAGES?.BLACK_ARROW : IMAGES.RIGHT_ARROW
                        }
                        className={styles.arrowStyles}
                      />
                    </button>
                  )}
                  <div className={styles.forgetContainer}>
                    <span
                      className={styles.forgetText}
                      onClick={() =>
                        navigate(
                          isTalent ? ROUTES.TALENT_LOGIN : ROUTES.CLIENT_LOGIN
                        )
                      }
                    >
                      Already a user? Sign In
                    </span>
                  </div>
                </div>
              </form>
            )}

          {(invitation?.invitation_accepted ||
            invitation?.invitation_expired ||
            other_invitations.filter((i) => i.invitation_accepted).length >=
              5) && (
            <div className="tw-mx-auto tw-rounded-md tw-h-[300px] tw-w-[525px] tw-my-8 tw-flex tw-flex-col tw-justify-center tw-text-center tw-p-16 tw-bg-white !tw-text-black">
              {(invitation?.invitation_accepted ||
                other_invitations.filter((i) => i.invitation_accepted).length >=
                  5) && (
                <p className="!tw-m-0 tw-font-bold tw-text-base !tw-text-black">
                  Your organization{" "}
                  <span className="tw-bg-[#011fc5] tw-text-white tw-p-1 tw-rounded-md">
                    {invitation?.sent_for?.name}
                  </span>{" "}
                  has already accepted the invite and joined TalentLab.
                </p>
              )}
              {invitation?.invitation_expired && (
                <p className="!tw-m-0 tw-font-bold tw-text-base !tw-text-black">
                  Your invite for joining{" "}
                  <span className="tw-bg-[#011fc5] tw-text-white tw-p-1 tw-rounded-md">
                    {invitation?.sent_for?.name}
                  </span>{" "}
                  on TalentLab has expired.
                </p>
              )}
              <p className="tw-text-black !tw-mx-0 !tw-my-2 ">
                Please contact{" "}
                <a
                  className="tw-text-[#011fc5] tw-pb-0.5 tw-border-b tw-border-current hover:!tw-text-black"
                  href="mailto:connect@talentlab.tech"
                >
                  connect@talentlab.tech
                </a>{" "}
                for more details.
              </p>
            </div>
          )}
        </div>
        {show_terms &&
          createPortal(
            <Terms
              close={() => {
                set_show_terms(false);
              }}
              accept={() => {
                sign_up_client();
              }}
            ></Terms>,
            document.body
          )}

        <LoginFooter />
      </div>
    </div>
  );
};

export default InvitationSignUp;
